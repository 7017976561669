import { get, post } from "@/utils/request";

export const sliceUpload = (formdata, config = {}) => {
  return post("file/upload", formdata, config);
};

export const mergeSlice = (hash) => {
  return post("file/merge", { hash: hash });
};
//抖音商品列表接口
export const getDouyinGoodsList = (params) => {
  return get("api/getDouyinGoodsList", params);
};


// 显示涨粉榜列表
export const shwoRanking = (params) => {
  return get("ranking/index", params);
};

// 显示涨粉榜分类
export const shwoCategory = (params) => {
  return get("ranking/category", params);
};

// 播主详情-数据概览 播主信息
export const showAuthorInfo = (params) => {
  return get("ranking/author", params);
};
// 电商视频排行的详情页接口
export const getTakeGoodsVideoDetail = (params) => {
  return get("api/getTakeGoodsVideoDetail", params);
};

// 播主详情-数据概览 数据概览
export const showRankingDetail = (id) => {
  return get("ranking/detail?userid=" + id);
};

// 播主详情-数据概览 粉丝、点赞、评论趋势
export const showRankingTrend = (id) => {
  return get("ranking/trend?userid=" + id);
};

// 播主详情-数据概览 近10个作品
export const showTopTen = (id) => {
  return get("ranking/product?userid=" + id);
};

// 播主详情-数据概览 手动刷新播主基础信息
export const getUpdateInfo = (params) => {
  return get("ranking/refresh", params);
};

// 播主详情-数据概览 粉丝分析
export const showFansData = (id) => {
  return get("ranking/fan?userid=" + id);
};

// 播主详情-数据概览 视频作品
export const showVideoData = (params) => {
  return get("ranking/video", params);
};

// 播主详情-数据概览 带货商品
export const showGoodsList = (params) => {
  return get("ranking/goods", params);
};
// 播主详情-数据概览 带货商品_分类
export const showGoodsCategory = (params) => {
  return get("ranking/goods_category", params);
};

// 播主详情-数据概览 带货分析 主营品类
export const showSellGoods = (params) => {
  return get("ranking/sell_goods", params);
};

// 播主详情-数据概览 带货分析 销量趋势图
export const salesEcharts = (params) => {
  return get("ranking/sales_chart", params);
};

// 播主详情-数据概览 带货分析 销售额趋势图
export const salesVolumeEcharts = (params) => {
  return get("ranking/amount_chart", params);
};

export const chanmamaDataAnalyseGetGoodsCategory = () => {
  return get("chanmama_data_analyse/getGoodsCategory");
};

export const chanmamaDataAnalyseGoodsRank = (params) => {
  return get("chanmama_data_analyse/goodsRank", params);
};

export const chanmamaDataAnalyseGetGoodsHead = (params) => {
  return get("chanmama_data_analyse/getGoodsHead", params);
};

export const chanmamaDataAnalyseReviewGoodsHead = (params) => {
  return get("chanmama_data_analyse/reviewGoodsHead", params);
};

export const articleCate = () => {
  return get("art/hotNewsCategory");
};

export const articleSearch = (param) => {
  return get("art/searchNew", param);
};

export const hotArticles = (param = {}) => {
  return get("art/hotTitle", param);
};
export const chanmamaDataAnalyseGoodsHeat = (params) => {
  return get("chanmama_data_analyse/goodsHeat", params);
};

export const chanmamaDataAnalyseDouyinVideoExtend = (params) => {
  return get("chanmama_data_analyse/douyinVideoExtend", params);
};

export const chanmamaDataAnalyseGoodsAnchorCategory = (params) => {
  return get("chanmama_data_analyse/goodsAnchorCategory", params);
};

export const chanmamaDataAnalyseAnchorGoods = (params) => {
  return get("chanmama_data_analyse/anchorGoods", params);
};

export const chanmamaDataAnalyseGoodsUser = (params) => {
  return get("chanmama_data_analyse/goodsUser", params);
};

export const newAccountVideoSummary = (params) => {
  return get("newAccount/videoSummary", params);
};

export const newAccountVideoComment = (params) => {
  return get("newAccount/videoComment", params);
};

export const newAccountVideoPerson = (params) => {
  return get("newAccount/videoPerson", params);
};

export const newAccountGetVideoCategory = (params) => {
  return get("newAccount/getVideoCategory", params);
};

export const newAccountGetHotVideoList = (params) => {
  return get("newAccount/getHotVideoList", params);
};

export const newAccountGetVideoGoodsAnalysis = (params) => {
  return get("newAccount/getVideoGoodsAnalysis", params);
};

export const getVideoGoodsChart = (params) => {
  return get("newAccount/getVideoGoodsChart", params);
};

export const newAccountGetVideoBashAnalysis = (params) => {
  return get("newAccount/getVideoBashAnalysis", params);
};

export const getVideoPlayCount = (params) => {
  return get("newAccount/getVideoBashPlay", params);
};

export const newAccountRefreshVideoDetail = (params) => {
  return post("newAccount/refreshVideoDetail", params);
};

export const newAccountRefreshVideoGoods = (params) => {
  return post("newAccount/refreshVideoGoods", params);
};

export const chanmamaGoodsOfficialGetGoodsCategory = () => {
  return get("chanmama_goods_official/getGoodsCategory");
};
export const chanmamaGoodsOfficialGoodsRank = (params) => {
  return get("chanmama_goods_official/goodsRank", params);
};
export const chanmamaGoodsOfficialGoodsHead = (params) => {
  return get("chanmama_goods_official/goodsHead", params);
};
export const chanmamaGoodsOfficialGoodsHot = (params) => {
  return get("chanmama_goods_official/goodsHot", params);
};
export const chanmamaGoodsOfficialGoodsVideo = (params) => {
  return get("chanmama_goods_official/goodsVideo", params);
};
export const rankingSalesGoodsAuthor = (params) => {
  return get("ranking/sales_goods_author", params);
};
export const chanmamaGoodsOfficialFans = (params) => {
  return get("chanmama_goods_official/fans", params);
};
export const chanmamaGoodsOfficialComments = (params) => {
  return get("chanmama_goods_official/comments", params);
};

export const importStuTmpl = (formdata) => {
  return post('user/import', formdata)
}

export const fansPicture = (author_id) => {
  return get("api/fans_data_analysis", {author_id: author_id})
}

export const viewserAnalysis = (aweme_id) => {
  return get("api/viewers_analysis", {aweme_id:aweme_id})
}

export const productVisual = (author_id) => {
  return get("api/product_visual", {author_id: author_id});
}

export const roughtlyManual = (author_id) => {
    return get("api/roughtly_manual", {author_id: author_id});
}

export const productAnalysis = (author_id) => {
    return get("api/product_analysis", {author_id: author_id})
}

export const awemeDetail = (param) => {
    return get("api/aweme_detail", param);
}

export const comprehensiveCharts = (param) => {
    return get("api/comprehensive", param)
}

export const liveGeneralData = (param) => {
    return get("live/index", param)
}

export const liveTrendsData = (param) => {
    return get("live/trends", param)
}

export const liveRoomData = (params) => {
    return get("live/room_list", params)
}

export const liveRoomDetail = (params) => {
    return get("live/room_more_data", params)
}

export const liveSummarize = (params) => {
    return get("live/summarize", params)
}

export const encryptData = (post_body) => {
    return post("tikhoknew/data/encrypt", post_body)
}

export const initOpenDouyinPartUpload = (params) => {
    return get("opendouyin/init", params)
}

export const completePartUpload = (params) => {
    return get("opendouyin/complete", params)
}

export const createVideo = (json_data) => {
    return post("opendouyin/create", json_data)
}
export const userModifyPassword = (post_body) => {
  return post("user/modifyPassword", post_body)
}

export const mediaDataList = () => {
  return get("collect/list");
}
