import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Router from 'vue-router' // 关于路由报错
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'src/assets/css/iconfont.css';
import 'src/assets/css/common.scss';
import Http from './utils/http.js';
import HttpStudent from './utils/httpStudent.js';
import HttpTeacher from './utils/httpTeacher.js';
import HttpCompany from './utils/httpCompany.js';
import HttpStuNoLoading from './utils/httpStuNoLoading.js';
import elTableInfiniteScroll from 'el-table-infinite-scroll';
import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'

Vue.use(elTableInfiniteScroll);
import Api from './utils/api.js';
import * as echarts from "echarts";

import { Popover } from 'element-ui';

import VueClipboard from 'vue-clipboard2';

// 关于路由报错
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error=> error)
}

Vue.prototype.$echarts = echarts;
Vue.use(VueClipboard);

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Popover);

Vue.prototype.$http = Http;
Vue.prototype.$httpStudent = HttpStudent;
Vue.prototype.$httpteacher = HttpTeacher;
Vue.prototype.$httpcompany = HttpCompany;
Vue.prototype.$httpStuNoLoading = HttpStuNoLoading;
Vue.prototype.$api = Api;


Vue.prototype.$tinymce = tinymce // 将全局tinymce对象指向给Vue作用域下
Vue.use(VueTinymce)              // 安装vue的tinymce组件

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
