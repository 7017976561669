<template>
    <div class="admin-container">
        <CompanyHeaderModule></CompanyHeaderModule>
        <div class="student-wrapper">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import CompanyHeaderModule from '../components/header/CompanyHeaderModule'
    export default {
        name: "CompanyLayout",
        components: {
            CompanyHeaderModule
        }
    }
</script>

<style scoped >

</style>