<template>
    <div class="student-content">
        <div class="left-wrapper">
            <el-scrollbar class="class-list" style="height: 100%;width: 221px" :native="false">
                <el-row class="tac">
                    <el-col :span="12">
                        <el-menu
                                :default-active=default_active
                                :default-openeds="opened"
                                unique-opened
                                class="el-menu-vertical-demo"
                                @open="handleOpen"
                                @close="handleClose">
                            <el-submenu index="studentWorksIndex">
                            <template slot="title">
                                <i class="iconfont">&#xe64b;</i>
                                <span slot="title">工作台</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item index="studentWorksIndex" @click="menuClick($event)">
                                    <router-link class="nav-item"  to="/student/works/index">工作台</router-link>
                                </el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                            <el-submenu index="studentAccount">
                                <template slot="title">
                                    <i class="iconfont">&#xe64c;</i>
                                    <span slot="title">创作中心</span>
                                </template>
                                <el-menu-item-group>
                                    <el-menu-item index="studentAccountIndex" @click="menuClick($event)">
                                        <router-link  class="nav-item" to="/student/account/index">账号管理</router-link>
                                    </el-menu-item>
<!--                                    <el-menu-item index="studentGraphicIndex" @click="menuClick($event)">-->
<!--                                        <router-link class="nav-item" to="/student/graphic/index">文章管理</router-link>-->
<!--                                    </el-menu-item>-->
                                    <el-menu-item index="studentVideoManagement" @click="menuClick($event)">
                                        <router-link class="nav-item" to="/student/video/management">视频管理</router-link>
                                    </el-menu-item>
                                    <!-- 暂时隐藏-->
                                    <el-menu-item index="studentLiveManagement" @click="menuClick($event)">
                                      <router-link class="nav-item" to="/student/live/management">直播管理</router-link>
                                    </el-menu-item>
<!--                                    <el-menu-item index="studentInteractiveIndex" @click="menuClick($event)">-->
<!--                                        <router-link  class="nav-item" to="/student/interactive/index">互动管理</router-link>-->
<!--                                    </el-menu-item>-->
<!--                                    <el-menu-item index="studentShortIndex" @click="menuClick($event)">-->
<!--                                        <router-link class="nav-item" to="/student/short/index">短视频创作</router-link>-->
<!--                                    </el-menu-item>-->
                                    <el-menu-item index="studentDataAnalysis" @click="menuClick($event)">
                                        <router-link class="nav-item" to="/student/dataAnalysis/index">数据分析</router-link>
                                    </el-menu-item>
                                    <el-menu-item index="studentScriptCreation" @click="menuClick($event)">
                                        <router-link class="nav-item" to="/student/script/creation">脚本创作</router-link>
                                    </el-menu-item>
                                    <el-menu-item index="studentVideoClip" @click="menuClick($event)">
                                        <router-link class="nav-item" to="/student/video/clip">视频剪辑</router-link>
                                    </el-menu-item>
                                    <!-- 暂时隐藏-->
                                    <!-- <el-menu-item index="studentProductPromotion" @click="menuClick($event)">
                                        <router-link class="nav-item" to="/student/productPromotion/index">商品推广</router-link>
                                    </el-menu-item> -->
                                </el-menu-item-group>
                            </el-submenu>
                            <el-submenu index="studentTask">
                                <template slot="title">
                                    <i class="iconfont">&#xe653;</i>
                                    <span slot="title">任务中心</span>
                                </template>
                                <el-menu-item-group>
                                    <el-menu-item index="studentTaskIndex" @click="menuClick($event)">
                                        <router-link class="nav-item" to="/student/task/index">任务大厅</router-link>
                                    </el-menu-item>
<!--                                    <el-menu-item index="studentGoodsIndex" @click="menuClick($event)">-->
<!--                                        <router-link class="nav-item" to="/student/goods/index">商品大厅</router-link>-->
<!--                                    </el-menu-item>-->
                                </el-menu-item-group>
                            </el-submenu>
                            <el-submenu index="studentHotScripts">
                                <template slot="title">
                                    <i class="iconfont">&#xe6fd;</i>
                                    <span slot="title">自媒体库</span>
                                </template>
                                <el-menu-item-group>
                                    <el-menu-item index="studentHot" @click="menuClick($event)">
                                        <router-link class="nav-item" to="/student/hot/scripts">热门脚本</router-link>
                                    </el-menu-item>
                                    <el-menu-item index="studentHotTopic" @click="menuClick($event)">
                                        <router-link class="nav-item" to="/student/hot/topic">热门话题</router-link>
                                    </el-menu-item>
                                    <el-menu-item index="studentHotArticles" @click="menuClick($event)">
                                        <router-link class="nav-item" to="/student/hot/articles">热门文章</router-link>
                                    </el-menu-item>
                                    <el-menu-item index="studentHotBroadcaster" @click="menuClick($event)">
                                        <router-link class="nav-item" to="/student/hot/broadcaster">热门播主</router-link>
                                    </el-menu-item>
                                    <el-menu-item index="studentHotVideo" @click="menuClick($event)">
                                        <router-link class="nav-item" to="/student/hot/video">热门视频</router-link>
                                    </el-menu-item>
                                    <el-menu-item index="mediaData" @click="menuClick($event)">
                                        <router-link class="nav-item" to="/student/hot/media">媒体实时数据</router-link>
                                    </el-menu-item>
                                </el-menu-item-group>
                            </el-submenu>
                            <el-submenu index="studentDsVideoRanking">
                                <template slot="title">
                                    <i class="iconfont">&#xe64a;</i>
                                    <span slot="title">电商分析</span>
                                </template>
                                <el-menu-item-group>
                                   <el-menu-item index="studentDsVideo" @click="menuClick($event)">
                                       <router-link class="nav-item" to="/student/ds/video/rank">电商视频排行</router-link>
                                   </el-menu-item>
                                    <el-menu-item index="studentDyProductsRank" @click="menuClick($event)">
                                        <router-link class="nav-item" to="/student/dy/products/rank">抖音商品排行
                                        </router-link>
                                    </el-menu-item>
                                   <el-menu-item index="studentTbProductsRank" @click="menuClick($event)">
                                       <router-link class="nav-item" to="/student/tb/promotion/rank">淘宝推广排行
                                       </router-link>
                                   </el-menu-item>
                                   <el-menu-item index="studentDyFavorite" @click="menuClick($event)">
                                       <router-link class="nav-item" to="/student/dy/favorite">抖音好物排行</router-link>
                                   </el-menu-item>
                                    <el-menu-item index="studentSalesRank" @click="menuClick($event)">
                                        <router-link class="nav-item" to="/student/ds/sales/rank">电商达人销量排行</router-link>
                                    </el-menu-item>
                                </el-menu-item-group>
                            </el-submenu>
                            <!--<div class="work-title">个人中心</div>-->
                            <!--<el-submenu index="studentDsVideoRanking">-->
                                <!--<template slot="title">-->
                                <!--<i class="iconfont">&#xe644;</i>-->
                                    <!--<span slot="title">个人中心</span>-->
                                <!--</template>-->
                            <!--</el-submenu>-->
                        </el-menu>
                    </el-col>
                </el-row>
            </el-scrollbar>
        </div>
        <el-scrollbar class="right-wrapper" :native="false">
<!--        <div class="right-wrapper">-->
            <div class="right-wrapper-content">
                <router-view @changeMenu="changeMenu"/>
            </div>
<!--        </div>-->
        </el-scrollbar>
    </div>
</template>

<script>
    export default {
        name: "StudentShortLayout",
        data() {
            return {
                default_active: 'studentWorksIndex',
                opened: ['studentWorksIndex'],
            }
        },
        created() {
            let index = localStorage.getItem('defaultActive');
            if (index){
                this.default_active = index;
            }
            //获取本地路由数据
            // let currentView = this.$route.path;
            // console.log(currentView)
            // switch (currentView) {
            //     case '/student/works/index':
            //         this.default_active = 'studentWorksIndex';
            //         break;
            //     case '/student/account/index':
            //         this.default_active = 'studentAccountIndex';
            //         break;
            //     case '/student/data/details':
            //         this.default_active = 'studentDataDetails';
            //         break;
            //     case '/student/graphic/creation':
            //         this.default_active = 'studentGraphicIndex';
            //         break;
            //     case '/student/short/index':
            //         this.default_active = 'studentShortIndex';
            //         break;
            //     case '/student/video/clip':
            //         this.default_active = 'studentVideoClip';
            //         break;
            //     case '/student/task/index':
            //         this.default_active = 'studentTaskIndex';
            //         break;
            //     case '/student/account/index':
            //         this.default_active = 'studentGoodsIndex';
            //         break;
            //     case '/student/hot/scripts':
            //         this.default_active = 'studentHot';
            //         break;
            //     case '/student/hot/topic':
            //         this.default_active = 'studentHotTopic';
            //         break;
            //     case '/student/hot/articles':
            //         this.default_active = 'studentHotArticles';
            //         break;
            //     case '/student/hot/broadcaster':
            //         this.default_active = 'studentHotBroadcaster';
            //         break;
            //     case '/student/hot/video':
            //         this.default_active = 'studentHotVideo';
            //         break;
            //     case '/student/ds/video/rank':
            //         this.default_active = 'studentDsVideo';
            //         break;
            //     case '/student/dy/products/ran   `k':
            //         this.default_active = 'studentDyProductsRank';
            //          break;
            //     case '/student/tb/promotion/rank':
            //         this.default_active = 'studentTbProductsRank';
            //         break;
            //     case '/student/dy/favorite':
            //         this.default_active = 'studentDyFavorite';
            //         break;
            //     case '/student/ds/sales/rank':
            //         this.default_active = 'studentSalesRank';
            //         break;
            //     case '/student/ds/sales/rank/details':
            //         this.default_active = 'studentDsSalesRankDetails';
            //         break
            // }
        },
        methods: {
            handleOpen(key, keyPath) {
                // console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                // console.log(key, keyPath);
            },
            menuClick(e){
                // console.log(e.index)
                this.default_active = e.index;
                localStorage.setItem('defaultActive', e.index);
            },
            changeMenu(val){
                this.default_active = val;
                localStorage.setItem('defaultActive', val);
            }
        }
    }
</script>

<style scoped lang="scss">
    .left-wrapper {
        .class-list {
            height: 1%;
            flex: 1;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }

        ::v-deep .el-menu {
            background-color: transparent;
            border-right: none;
        }

        ::v-deep .el-col-12 {
            width: 100%;
        }

        ::v-deep .el-submenu__title {
            color: #685EF3;

            i {
                color: #685EF3;
                margin-right: 9px;
                font-size: 20px;
                text-align: center;
                vertical-align: middle;
            }

            &:hover {
                background-color: transparent;
            }
        }

        ::v-deep .el-menu-item {
            color: #fff;
        }

        ::v-deep .el-menu li.el-menu-item-group ul li.el-menu-item {
            background-color: #F1F0FE;
            border-left: 2px solid transparent;
            color: #fff;
        }

        ::v-deep .el-submenu .el-menu-item {
            padding-left: 44px;
        }

        ::v-deep .el-menu-item-group {
            background: #F1F0FE;
        }

        ::v-deep .el-menu-item-group>ul {
            padding-bottom: 14px;
        }

        ::v-deep .el-menu li.el-menu-item-group ul li.is-active {
            background-color: #9ACC5B;
            border-radius: 22px;
            /*border-left: 2px solid #20c997;*/
            color: #fff;
        }

        ::v-deep .el-menu li.el-menu-item-group ul li.is-active a{
            color: #fff;
        }

        /*::v-deep a:focus, a:hover {*/
        /*    color: #fff;*/
        /*}*/

        .nav-item {
            padding: 0 20px;
            display: block;
            color: #3A3567;
        }

        .work-title {
            height: 56px;
            line-height: 56px;
            position: relative;
            -webkit-box-sizing: border-box;
            white-space: nowrap;
            list-style: none;
            padding-left: 20px;
            font-size: 14px;
            cursor: pointer;
            transition: border-color .3s, background-color .3s, color .3s;
            box-sizing: border-box;
            color: #fff;
        }
    }
    .student-content{
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        .left-wrapper {
            z-index: 1;
            width: 221px;
            background-color: #fff;
            height: 100%;
            box-shadow: 0px 0px 17px 1px rgba(11, 12, 28, 0.09);
        }
        ::v-deep .right-wrapper{
            width: 1%;
            flex: 1;
            //padding: 20px;
            //overflow: auto;
            & > .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            & > .el-scrollbar__wrap > .el-scrollbar__view {
                min-width: 1000px;
                height: 100%;
            }
            .right-wrapper-content {
                //min-width: 1000px;
                padding: 20px;
                position: relative;
                background: #F1F0FE;
                /*height: calc(100% - 40px);*/
            }
        }
        .main-wrapper {
            background: #fff;
            border-radius: 4px;
            height: 100%;
            width: 1%;
            flex: 1;
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
            box-sizing: border-box;
        }
    }
</style>
