<template>
    <div class="admin-container">
        <SchoolHeaderModule></SchoolHeaderModule>
        <div class="admin-wrapper">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import SchoolHeaderModule from '../components/header/SchoolHeaderModule'

    export default {
        components: {
            SchoolHeaderModule
        }
    }
</script>

<style scoped>

</style>