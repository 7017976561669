<template>
    <div class="admin-content">
        <div class="left-wrapper">
            <el-menu :default-active="adminTab" class="left-nav">
                <el-menu-item index="adminTrainClassification">
                    <router-link class="nav-item" to="/admin/train/classification">实训分类</router-link>
                </el-menu-item>
                <el-menu-item index="adminTrainList">
                    <router-link class="nav-item" to="/admin/train/list">实训列表</router-link>
                </el-menu-item>
                <el-menu-item index="adminCreateTrain">
                    <router-link class="nav-item" to="/admin/train/createtrain">创建实训</router-link>
                </el-menu-item>
            </el-menu>
        </div>
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: "AdminTrainLayout",
        data() {
            return {
                adminTab: '',
            }
        },
        mounted() {
            this.adminTab = this.$route.name;
        },
        watch:{
            $route(to,from){
                // console.log(from.path);//从哪来
                // console.log(to.path);//到哪去
                this.adminTab = this.$route.name;
            }
        }
    }
</script>

<style scoped lang="scss">
    .left-nav {
        .el-menu-item {
            padding: 0!important;
            height: 46px;
            line-height:46px;
            border-left: 2px solid transparent;
            .nav-item {
                padding: 0 20px;
                display: block;
                color: #333;
            }
            &.is-active {
                height:46px;
                line-height:46px;
                .nav-item {
                    color: #1E34E4;
                }
            }
            &.is-active:after{
                position: absolute;
                content: '';
                height: 14px;
                width: 3px;
                background: #1E34E4;
                top: 16px;
            }
            &.el-menu-item:focus, &.el-menu-item:hover {
                outline: 0;
                background-color: transparent;
            }

        }
    }

</style>