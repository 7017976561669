<template>
    <div class="admin-container">
        <StudentHeaderModule></StudentHeaderModule>
        <div class="student-wrapper">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import StudentHeaderModule from '../components/header/StudentHeaderModule'
    export default {
        name: "StudentLayout",
        components: {
            StudentHeaderModule
        }
    }
</script>

<style scoped >

</style>