<template>
    <div class="admin-container">
        <HeaderModule></HeaderModule>
        <div class="admin-wrapper">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import HeaderModule from '../components/header/AdminHeaderModule'
    export default {
        name: "AdminLayout",
        components: {
            HeaderModule
        }
    }
</script>

<style scoped>

</style>