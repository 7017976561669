import axios from "axios";
import {Message} from "element-ui"

const axiosInstance = axios.create({
	timeout: 10000
});

const tokenList = {
	1: "token",
	2: "token",
	3: "teacherToken",
	4: "studentToken"
}


axiosInstance.interceptors.request.use((config) => {
	// console.log('token',localStorage.getItem(tokenList[Number(localStorage.getItem('role'))]));
	config.headers.Authorization = localStorage.getItem(tokenList[Number(localStorage.getItem('role'))])
	return config;
}, (err) => {
	return Promise.reject(err);
});

axiosInstance.interceptors.response.use((response) => {
	 if (response.data.code === 8) {
		 Message.warning("账号绑定信息失效，需要重新绑定，请到直播管理选择账号重新绑定抖音账号")
	 }
	return response.data;
}, (error) => {
	return Promise.reject(error);
});

export const get = (url, params={}) => {
	return axiosInstance.get(url, {params: params});
}

export const post = (url, params={}, config={}) => {
	return axiosInstance.post(url, params, config);
}
